import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./background.scss";

const BackgroundComponent = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        // particles: {
        //   number: {
        //     value: 30,
        //     density: {
        //       enable: true,
        //       area: 800,
        //     },
        //   },
        //   color: {
        //     value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"],
        //   },
        //   shape: {
        //     type: "circle",
        //   },
        //   opacity: {
        //     value: 1,
        //   },
        //   size: {
        //     value: { min: 1, max: 8 },
        //   },
        //   links: {
        //     enable: true,
        //     distance: 150,
        //     color: "#808080",
        //     opacity: 0.4,
        //     width: 1,
        //   },
        //   move: {
        //     enable: true,
        //     speed: 1,
        //     direction: "none",
        //     random: false,
        //     straight: false,
        //     outModes: "out",
        //   },
        // },
        background: {
          color: {
            value: "#1a1a1a",
          },
        },
      }}
    ></Particles>
  );
};

export default BackgroundComponent;
