import React from "react";
import { restartLevel } from "../../services/handlers";

import "./popup-restart-level.scss";

const PopupRestartLevelComponent = ({ closePopup }) => {
  const ClickClosePopup = () => {
    closePopup();
  };

  const ClickRestartLevel = () => {
    restartLevel();
  };

  return (
    <div className="popup-restart-level-component" onClick={ClickClosePopup}>
      <div className="div-popup-restart-level">
        <h3 className="h3-popup-restart-level">Attention</h3>

        <p className="p-popup-restart-level">Êtes-vous sûr de vouloir recommencer ce niveau et donc perdre votre progression actuelle ?</p>

        <div className="div-buttons-action">
          <button className="secondary-button" onClick={ClickRestartLevel}>
            Recommencer
          </button>
          <button className="primary-button" onClick={ClickClosePopup}>
            <div className="div-button-next-level">Annuler</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupRestartLevelComponent;
