import React, { useState } from "react";

import ms from "pretty-ms";

import "./timer.scss";
import eventBus from "../../services/eventBus";
import { saveTimer } from "../../services/levels";

const TimerComponent = () => {
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(Date.now());
  const [stop, stopTime] = useState(null);

  startTimer();

  React.useEffect(() => {
    eventBus.on("haveWin", () => {
      stopTime(Date.now());
      saveTimer(ms(Date.now() - start, { secondsDecimalDigits: 0 }));
    });

    eventBus.on("restartLevel", () => {
      resetTimer();
    });

    eventBus.on("getNextLevel", () => {
      resetTimer();
    });
  });

  function startTimer() {
    setInterval(() => setTime(Date.now() - start), 1000);
  }

  function resetTimer() {
    stopTime(null);
    setStart(Date.now());
  }

  return (
    <div>
      {(Date.now() - start) > 1000 && 
        <span>
          Temps :&nbsp;
          { stop ? ms(stop - start, { secondsDecimalDigits: 0 }) : ms(Date.now() - start, { secondsDecimalDigits: 0 })}
          </span>
        }
    </div>
  );
};

export default TimerComponent;
