import React, { useState } from "react";

import lodash from "lodash";

import { checkLevel, saveMoves } from "../../services/levels";
import eventBus from "../../services/eventBus";

import "./game.scss";
import TimerComponent from "../timer/timer";

const GameComponent = ({ level }) => {
  const [levelArray, setLevel] = useState(level.split("-"));
  const [xSelected, setXSelected] = useState(0);
  const [ySelected, setYSelected] = useState(0);
  var [moves, updateMoves] = useState(0);

  React.useEffect(() => {
    checkLevel(levelArray.toString().replace(/,/g, "-"));
    saveMoves(moves);

    eventBus.on("joystickKeyHandler", (data) => {
      handleKeyDown({ key: data.key });
    });

    eventBus.on("restartLevel", () => {
      setLevel(level.split("-"));
      updateMoves(0);
    });

    eventBus.on("getNextLevel", (data) => {
      setLevel(data.level.split("-"));
      updateMoves(0);
    });
  }, [levelArray]);

  function handleKeyDown(event) {
    let cloneArray = lodash.cloneDeep(levelArray);

    if (event.key === "ArrowRight") {
      const lastElement = cloneArray[ySelected][cloneArray[ySelected].length - 1];
      const element = cloneArray[ySelected].substring(0, cloneArray[ySelected].length - 1);
      cloneArray[ySelected] = lastElement + element;

      setLevel(cloneArray);
      updateMoves(moves + 1);
    } else if (event.key === "ArrowLeft") {
      const firstElement = cloneArray[ySelected][0];
      const element = cloneArray[ySelected].substring(1);
      cloneArray[ySelected] = element + firstElement;

      setLevel(cloneArray);
      updateMoves(moves + 1);
    } else if (event.key === "ArrowUp") {
      let horizontalLine = [];

      for (const line of cloneArray) {
        horizontalLine.push(line[xSelected]);
      }

      const firstElement = horizontalLine.shift();
      horizontalLine.push(firstElement);

      for (const [index, char] of horizontalLine.entries()) {
        let line = cloneArray[index].split("");
        line.splice(xSelected, 1, char);
        line = line.join("");

        cloneArray[index] = line;
      }

      setLevel(cloneArray);
      updateMoves(moves + 1);
    } else if (event.key === "ArrowDown") {
      let horizontalLine = [];

      for (const line of cloneArray) {
        horizontalLine.push(line[xSelected]);
      }

      const lastElement = horizontalLine.pop();
      horizontalLine.unshift(lastElement);

      for (const [index, char] of horizontalLine.entries()) {
        let line = cloneArray[index].split("");
        line.splice(xSelected, 1, char);
        line = line.join("");

        cloneArray[index] = line;
      }

      setLevel(cloneArray);
      updateMoves(moves + 1);
    } else if (event.key === "z") {
      let y = ySelected - 1;
      if (y < 0) y = levelArray.length - 1;

      setYSelected(y);
    } else if (event.key === "s") {
      let y = ySelected + 1;
      if (y > levelArray.length - 1) y = 0;

      setYSelected(y);
    } else if (event.key === "q") {
      let x = xSelected - 1;
      if (x < 0) x = levelArray[0].length - 1;

      setXSelected(x);
    } else if (event.key === "d") {
      let x = xSelected + 1;
      if (x > levelArray[0].length - 1) x = 0;

      setXSelected(x);
    }
  }

  function selectLine(x, y) {
    setXSelected(x);
    setYSelected(y);
  }


  const lineboard = (line, index) =>
    line.split("").map((char, i) => (
      <span className="char" key={String(index) + String(i)} data-color={char} data-active-x={i === xSelected} onClick={selectLine.bind(this, i, index)}>
        {char}
      </span>
    ));

  const gameboard = levelArray.map((line, index) => (
    <span className="line" key={"Line " + index} data-active-y={index === ySelected} data-first-child={index === 0} data-last-child={index === levelArray.length - 1}>
      {lineboard(line, index)}
    </span>
  ));

  return (
    <div className="game-component" onKeyDown={handleKeyDown} tabIndex="0">
      {gameboard}

      <div className="div-infos-game">
        <TimerComponent/>
        <span>
          {moves} {moves > 1 ? "coups" : "coup"}
        </span>
      </div>
    </div>
  );
};

export default GameComponent;
