import React from "react";
import { joystickKeyHandler } from "../../services/handlers";
import "./joysticks.scss";

const JoysticksComponent = () => {
  const ClickHandler = (key) => {
    joystickKeyHandler(key);
  };

  return (
    <div className="joysticks-component">
      <div className="row">
        <button type="button" onClick={ClickHandler.bind(this, "ArrowUp")} className="btn2">
          <span>
            <span className="up"></span>
          </span>
        </button>
      </div>

      <div className="row">
        <button type="button" onClick={ClickHandler.bind(this, "ArrowLeft")} className="btn2">
          <span>
            <span className="left"></span>
          </span>
        </button>
        <button type="button" onClick={ClickHandler.bind(this, "ArrowDown")} className="btn2">
          <span>
            <span className="down"></span>
          </span>
        </button>
        <button type="button" onClick={ClickHandler.bind(this, "ArrowRight")} className="btn2">
          <span>
            <span className="right"></span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default JoysticksComponent;
