import React from "react";

import "./popup-how-to-play.scss";

const PopupHowToPlayComponent = ({ closePopup }) => {
  const ClickClosePopup = () => {
    closePopup();
  }

  return (
    <div className="popup-how-to-play-component" onClick={ClickClosePopup}>
      <div className="div-popup-how-to-play">
        <h3 className="h3-popup-how-to-play">Comment jouer ?</h3>

        <p className="p-popup-how-to-play">Le but est de déplacer les blocs circulaires pour reproduire l'objectif situé en bas à droite.</p>

        <h4 className="h4-popup-how-to-play">Contrôles</h4>
        <ol>
          <li>Déplacez la ligne ou la colonne en appuyant sur les touches fléchées de votre clavier ou sur celles situées en dessous du jeu.</li>
          <li>Pour sélectionner la ligne/colonne à déplacer, vous pouvez utiliser les touches Z, Q, S, D ou cliquer sur le bloc circulaire, ce qui sélectionnera sa ligne et sa colonne.</li>
          <li>Les commandes peuvent également être personnalisées selon vos préférences en cliquant sur le bouton avec la roue crantée.</li>
        </ol>

        <p>Remarque : le jeu reprend là où vous l'avez laissé. Ne vous souciez donc pas de fermer votre fenêtre. Si vous ouvrez à nouveau le jeu (dans le même navigateur et sur le même appareil), il recommencera à partir du point que vous avez quitté.</p>
      
        <h4 className="h4-popup-how-to-play">Amusez-vous bien 😁</h4>
      </div>
    </div>
  );
};

export default PopupHowToPlayComponent;
