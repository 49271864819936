import React, { useState } from "react";

import "./game.scss";
import { getLevel, getActualLevel, getTimer, getMoves } from "../../services/levels";

import GameComponent from "../../components/game/game";
import GoalComponent from "../../components/goal/goal";
import BackgroundComponent from "../../components/background/background";
import LogoComponent from "../../components/logo/logo";
import GameMenuComponent from "../../components/game-menu/game-menu";
import PopupLevelCompletedComponent from "../../components/popup-level-completed/popup-level-completed";
import JoysticksComponent from "../../components/joysticks/joysticks";

import eventBus from "../../services/eventBus";
import { getNextLevel, restartLevel } from "../../services/handlers";

const Game = () => {
  const [level] = useState(getLevel());
  const [actualLevel, setActuelLevel] = useState(getActualLevel());
  const [haveWin, setHaveWin] = useState(false);

  React.useEffect(() => {
    eventBus.on("haveWin", () => {
      setHaveWin(true);
    });
  });

  function closePopupAndRestartLevel() {
    setHaveWin(false);
    restartLevel();
  }

  function closePopupAndGoNextLevel() {
    setHaveWin(false);
    setActuelLevel(getActualLevel());
    getNextLevel();
  }

  return (
    <div className="game">
      <BackgroundComponent />
      <LogoComponent />
      <GameMenuComponent level={actualLevel} />

      <div className="div-game-goal">
        <GameComponent level={level[0]} />

        <GoalComponent level={level[1]} />

        <div className="div-joystick-component">
          <JoysticksComponent />
        </div>
      </div>

      {haveWin && <PopupLevelCompletedComponent level={actualLevel} restartlevel={closePopupAndRestartLevel} goNextLevel={closePopupAndGoNextLevel} />}
    </div>
  );
};

export default Game;
