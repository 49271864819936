import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./popup-level-completed.scss";
import { getMoves, getTimer } from "../../services/levels";
import eventBus from "../../services/eventBus";

const PopupLevelCompletedComponent = ({ level, restartlevel, goNextLevel }) => {
  const [moves, setMoves] = useState(getMoves());
  const [timer, setTimer] = useState(getTimer());

  React.useEffect(() => {
    eventBus.on("haveWin", () => {
      setTimer(getTimer());
      setMoves(getMoves());
    });
  });

  const clickRestartlevel = () => {
    return restartlevel();
  };

  const clickGoNextLevel = () => {
    return goNextLevel();
  };

  return (
    <div className="popup-level-completed-component">
      <div className="div-popup-level-completed">
        <h3 className="h3-popup-level-completed">Niveau {level} terminé !</h3>
        <p className="p-popup-level-completed">
          <span className="btn-primary-color">
            <b>Félications ! </b>
          </span>
          Vous avez terminé ce niveau en {timer} (<span className="btn-primary-color">{moves} mouvements</span>).
          <br />
          Vous pouvez tenter le prochain niveau ou recommencer ce niveau pour améliorer votre temps.
        </p>

        <div className="div-buttons-popup-level-completed">
          <button className="secondary-button" onClick={clickRestartlevel.bind()}>
            Recommencer
          </button>
          <button className="primary-button" onClick={clickGoNextLevel.bind()}>
            <div className="div-button-next-level">
              Prochain niveau
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupLevelCompletedComponent;
