import React from 'react';

import Game from './pages/game/game';

import './App.scss';

function App() {
  return (
    <Game />
  );
}

export default App;
