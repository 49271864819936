import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";

import PopupHowToPlayComponent from "../popup-how-to-play/popup-how-to-play";
import PopupRestartLevelComponent from "../popup-restart-level/popup-restart-level";

import "./game-menu.scss";

const GameMenuComponent = ({ level }) => {
  const [displayPopupHowToPlay, openHowToPlay] = useState(false);
  const [displayPopupRestartLevel, openRestartLevel] = useState(false);

  function closePopupHowToPlay() {
    openHowToPlay(false);
  }

  function closePopupRestartLevel() {
    openRestartLevel(false);
  }

  return (
    <div className="game-menu-component">
      <div className="div-button-how-to-play">
        <button className="button-how-to-play" onClick={openHowToPlay.bind(true)}>
          Comment jouer ?
        </button>
      </div>

      <div className="div-buttons-level">
        <button className="button-display-level">Niveau #{level}</button>
        <button className="button-restart-level" onClick={openRestartLevel.bind()}>
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
      </div>

      {displayPopupHowToPlay && <PopupHowToPlayComponent closePopup={closePopupHowToPlay} />}

      {displayPopupRestartLevel && <PopupRestartLevelComponent closePopup={closePopupRestartLevel} />}
    </div>
  );
};

export default GameMenuComponent;
