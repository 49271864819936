import { haveWin } from "./handlers";

var actualIndex = 0;
var actualLevelGoal = "";
var timer = "";
var moves = 0;

export function getActualLevel() {
  return Number(actualIndex) + 1;
}

export function getMoves() {
  return moves;
}

export function saveMoves(m) {
  return moves = m;
}

export function getTimer() {
  return timer;
}

export function saveTimer(t) {
  return timer = t;
}

export function getLevel(haveWin = false) {
  actualIndex = localStorage.getItem("level") || 0;

  if (haveWin) {
    localStorage.setItem("level", Number(actualIndex) + 1);
    actualIndex++;
  }

  const listOfLevels = [
    ["gygy-ygyg-gygy-ygyg", "gggg-yyyy-gggg-yyyy"],
    ["yyyy-ygry-yrgy-yyyy", "yyyy-yrry-yggy-yyyy"],
    ["ryyr-yyyy-yyyy-ryyr", "yyyy-yrry-yrry-yyyy"],
    ["yggg-rrry-yyyy-yyyy", "ggyy-gyyy-yyyr-yyrr"],
    ["rrry-yyyy-yyyy-yggg", "ryyg-ygyy-yyry-ryyg"],
    ["yryy-rrry-yyyg-gygy", "rgyy-yrgy-yyrg-yyyr"],
    ["grgy-yrgr-ggyg-grgy", "ygry-rggg-gggr-yrgy"],
    ["yggy-grrg-grrg-yggy", "rggr-gyyg-gyyg-rggr"],
    ["rgyg-ygyr-yggg-ggrr", "yggr-gyrg-gryg-rggy"],
    ["ryry-pyyr-ygry-pyyg", "ryyr-ypgy-ypgy-ryyr"],
    ["gyyg-gryp-pryy-yyrp", "pppy-yrrr-gggy-yyyy"],
    ["rryy-rryy-ggpp-ggpp", "rryy-rgpy-ggpp-rgpy"],
    ["ryyr-pggp-pggp-yggy", "gpgy-pryg-gygp-ygpr"],
    ["rgrg-prpp-ygry-pyyg", "ygyg-gygy-prpr-rprp"],
    ["rgrg-ggyg-ppyy-gypp", "ggyp-pgyr-pgyr-ggyp"],
    ["yrrg-pryp-ggpr-pygy", "rpgy-rpgy-rpgy-rpgy"],
    ["yrcg-yrgc-ygpg-pppy", "yyyy-rccr-ggpp-ggpp"],
    ["ggcy-gpcg-pyrc-ccrc", "rppr-gccg-cyyc-gccg"],
    ["rrgy-ppcg-gcrc-gyyy", "ycgp-rycg-gryc-pgry"],
    ["gcyg-rtyr-ypcg-ptrt", "rryy-rpcy-gcpt-ggtt"],
    ["gcgy-tgpr-pgyc-trtt", "pttp-tcct-grrg-yggy"],
    ["ygrc-ntny-pgrg-tpgc", "gngn-cgcg-pprr-yytt"],
    ["cgyg-ggnp-rycr-ttnp", "gtyn-tgrp-yrgc-npcg"],
    ["tcng-pity-ipnr-rygc", "ttcc-iipp-rrgg-yynn"],
    ["tcng-pity-ipnr-rygc", "icpn-cinp-rygt-yrtg"],
    ["gggg-gggg-yyyy-gygy-ygyg", "gggg-yyyy-gggg-yyyy-gggg"],
  ];

  actualLevelGoal = listOfLevels[actualIndex][1];

  return listOfLevels[actualIndex];
}

export function checkLevel(data) {
  if (data === actualLevelGoal) {
    haveWin();
    return true;
  }
}
