import React, { useContext } from "react";
import eventBus from "./eventBus";
import { getLevel } from "./levels";

const ConversationsContext = React.createContext();

export function useConversations() {
  return useContext(ConversationsContext);
}

export function joystickKeyHandler(key) {
  eventBus.dispatch("joystickKeyHandler", { key: key });
  return;
}

export function restartLevel() {
  eventBus.dispatch("restartLevel");
  return;
}

export function haveWin() {
  eventBus.dispatch("haveWin");
  return;
}

export function getNextLevel() {  
  const level = getLevel(true);
  eventBus.dispatch("getNextLevel", { level: level[0], goal: level[1] });
  return;
}
