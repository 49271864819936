import React, { useState } from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faSyncAlt, faUndoAlt, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import { faSave } from '@fortawesome/free-solid-svg-icons';

// import LottieAnimation from '../../components/Lottie/Lottie';
// import lottieConfettiVictory from '../../assets/lottie-confetti-victory.json';
// import lottieConfettiCompleteOneContainer from '../../assets/lottie-confetti-complete-one-container.json';
// import ms from 'pretty-ms';

import "./goal.scss";
import eventBus from "../../services/eventBus";

const GoalComponent = ({ level }) => {
  const [levelArray, setGoal] = useState(level.split("-"));

  React.useEffect(() => {
    eventBus.on("getNextLevel", (data) => {
      setGoal(data.goal.split("-"));
    });
  }, [levelArray]);

  const lineboard = (line, index) =>
    line.split("").map((char, i) => (
      <span className="char" key={"Goal" + String(index) + String(i)} data-color={char}>
        {char}
      </span>
    ));

  const gameboard = levelArray.map((line, index) => (
    <span className="line" key={"Line " + index}>
      {lineboard(line, index)}
    </span>
  ));

  return (
    <div className="goal-component">
      <h3 className="h3-goal">Votre objectif</h3>
      {gameboard}
    </div>
  );
};

export default GoalComponent;
