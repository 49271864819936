import React from "react";
import "./logo.scss";

const LogoComponent = () => {
  return (
    <div className="logo-component">
      <span className="first-part-logo">Align'em</span>
      <span className="second-part-logo">All</span>
    </div>
  );
};

export default LogoComponent;
